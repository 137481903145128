
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmWeekSchedule from '@/components/shared/TmWeekSchedule.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { weekScheduleDefault } from '@/definitions/_general/_data/general'

export default defineComponent({
  components: { TmButton, TmModal, TmFormLine, TmWeekSchedule },
  props: {
    label: {
      type: String,
      default: 'Number business hours',
    },
  },
  setup() {
    const hoursState = ref('always')
    const weekSchedule = ref(weekScheduleDefault)

    return {
      weekSchedule,
      hoursState,
    }
  },
})
